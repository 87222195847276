<template>
  <div class="content-partial-payments-all">
    <div class="content-btn-action">
      <v-btn
        color="primary"
        width="150"
        class="btn-primary-global"
        @click="setPay({})"
        >+ Añadir pago</v-btn
      >
    </div>
    <v-card class="content-card-table-partial-payments">
      <v-simple-table class="simple-table-materials" fixed-header>
        <template v-slot:default>
          <thead v-if="!bLoadingTable">
            <tr>
              <th class="text-center">
                #
              </th>
              <th class="text-left">
                Descripción de pago
              </th>
              <th class="text-center w-150">
                Fecha
              </th>
              <th class="text-end w-200">
                Cantidad registrada
              </th>
            </tr>
          </thead>
          <tbody v-if="!bLoadingTable">
            <tr translate="no" v-for="(item, index) in aItems" :key="index">
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-left">{{ item.sDescription }}</td>
              <td class="text-center">{{ item.tTransactionDate }}</td>
              <td class="text-end">
                ${{ getFormatMoney(item.dAmount) }} MXN
              </td>
            </tr>
            <tr v-if="bEmptyTable">
              <td colspan="4">
                <div class="text-center">
                  <span class="txt-empty-global">
                    Sin información
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
          <div v-show="bLoadingTable">
            <v-progress-linear
              indeterminate
              color="yellow darken-2"
            ></v-progress-linear>
            <div class="content-text-loading">
              <p class="text-loading">Cargando... Espere por favor</p>
            </div>
          </div>
        </template>
      </v-simple-table>
    </v-card>

    <div class="content-totals-all">
      <v-card class="content-card-total-all">
        <div
          v-for="(item, index) in aItemsTotals"
          :key="index"
          class="content-card-total"
        >
          <div class="content-left-card">
            <span>{{ item.sName }}</span>
            <span class="mx-3">|</span>
          </div>
          <div class="content-right-card" :class="item.sClass">
            ${{ getFormatMoney(item.dQuantity) }} MXN
          </div>
        </div>
      </v-card>
    </div>
    <dialog-partial-payments
      @close="openDialogPayment = false"
      :bActive="openDialogPayment"
      :item="itemToPay"
      :dTotalPayments="dTotalPayments"
      :sOCId="sOCId"
    />
  </div>
</template>
<script>
export default {
  components: {
    //import dialog partial payments component
    DialogPartialPayments: () => import("./dialog-partial-payments"),
  },
  data() {
    return {
      openDialogPayment: false,
      itemToPay: null,
      aItems: [],
      bLoadingTable: true,
      bEmptyTable: false,
      aItemsTotals: [
        {
          sValue: "dPTotal",
          sName: "TOTAL DE LA ORDEN",
          dQuantity: 0,
          sClass: "color-grey-global",
        },
        {
          sValue: "dTotalPayments",
          sName: "TOTAL PAGADO",
          dQuantity: 0,
          sClass: "color-green-subtotal-global",
        },
        {
          sValue: "dTotalRemaining",
          sName: "TOTAL RESTANTE",
          dQuantity: 0,
          sClass: "color-red-global",
        },
      ],
      dTotalPayments: 0,
      sOCId:""
    };
  },
  beforeMount() {
    this.sOCId = this.$route.params.sOCId;
    this.getPartialPayments();
  },
  methods: {
    getPartialPayments() {
      db.get(
        `${uri}/api/v1/purchase_order/order/${this.$route.params.sOCId}/payments`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
          params: {
            // sSearch: this.sSearch,
            // iPageNumber: 1,
            // iItemsPerPage: 0,
            // iPageNumber: this.iCurrentPage,
            // iItemsPerPage: this.iItemsPerPage,
          },
        }
      )
        .then((resp) => {
          this.aItems = resp.data.results.aList;
          this.dTotalPayments = resp.data.results.dTotalPayments;
          let aTotalsTemp = Object.entries(resp.data.results);

          for (const i in this.aItemsTotals) {
            aTotalsTemp.forEach(([key, value]) => {
              if (this.aItemsTotals[i].sValue === key) {
                this.aItemsTotals[i].dQuantity = value;
              }
            });
          }

          this.bLoadingTable = false;
          this.bEmptyTable = this.aItems.length === 0;

          this.$store.commit("refresher", false);
        })
        .catch((err) => {
          this.bLoadingTable = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getFormatMoney(number) {
      let convert_number = Number(number).toFixed(2);
      return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setPay(item) {
      this.itemToPay = item;
      this.openDialogPayment = true;
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refreshTable: function() {
      this.getPartialPayments();
    },
  },
};
</script>
<style>
.content-btn-action {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}
.content-card-table-partial-payments {
  border-radius: 20px !important;
  padding: 20px !important;
}
.content-totals-all {
  display: flex;
  justify-content: flex-end;
}
.content-card-total-all {
  border-radius: 20px !important;
  padding: 20px !important;
  width: 500px;
  margin-top: 20px;
}
.content-card-total {
  display: flex;
  width: 100%;
}
.content-left-card {
  width: 100%;
  text-align: right;
  color: black;
  font-weight: bold;
}
.content-right-card {
  min-width: 200px;
  text-align: right;
  font-weight: bold;
  /* background-color: blue; */
}
</style>
